<template>
  <div class="root">
    <b-overlay :show="loading" rounded="lg" opacity="0.6">
      <v-card class="text-center mt-10" flat shaped>
        <v-card-title>
          <!-- params.id varsa dashboard değildir yani geri gitme butonu koyabiliriz -->
          <b-button
            v-if="this.$route.params.id > 0"
            size="sm"
            class="mx-3 mt-0 geri"
            id="geri"
            variant="outline-primary"
            @click="goBack()"
          >
            <b-icon icon="arrow90deg-left"></b-icon>
          </b-button>
          <h3 class="mt-1">{{ title ? title : 'Sınav Oturumları' }}</h3>
        </v-card-title>
        <v-card-text>
          <div class="pt-5" v-if="examSessions.length > 0">
            <div
              class="d-flex align-items-center mb-8"
              v-for="item in examSessions.slice(0, count)"
              :key="item.Id"
            >
              <div class="flex-grow-1 text-left">
                <a
                  @click="
                    ExamSessionId = item.Id
                    StartDate = item.StartDate
                    EndDate = item.EndDate
                    getExamSessionStudentFromApi()
                  "
                >
                  <a href="#" class="text-gray-800 text-hover-primary fw-bolder fs-6 mt-1"
                    >{{ item.Name }} <br />
                    Süre: {{ item.Duration }} dk <br />
                  </a>
                </a>
                <div class="text-gray-800 text-hover-primary fw-bolder fs-6 mt-1">
                  Sınav Başlangıç: {{ item.StartDate | moment }}
                </div>
                <div class="text-gray-800 text-hover-primary fw-bolder fs-6 mt-1">
                  Sınav Bitiş: {{ item.EndDate | moment }}
                </div>
                <hr />
              </div>
              <a
                @click="
                  ExamSessionId = item.Id
                  StartDate = item.StartDate
                  EndDate = item.EndDate
                  getExamSessionStudentFromApi()
                "
              >
                <a class="btn btn-icon btn-light btn-hover-primary btn-sm">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <inline-svg src="/media/svg/icons/Navigation/Arrow-right.svg"></inline-svg>
                  </span>
                </a>
              </a>
            </div>
            <v-dialog v-model="dialog" max-width="350px">
              <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                  <v-container fluid class="text-center text-break">
                    <h2 v-if="examSessionStudentId">BU SINAVDA KAYDINIZ BULUNMAKTADIR</h2>
                    <h2 v-else>OTURUMA GİRDİĞİNİZDE SÜRENİZ BAŞLAYACAKTIR</h2>
                    <v-switch v-model="IsDigital" hide-details label="Online Kitapçık"></v-switch>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="close()"> VAZGEÇ </v-btn>
                  <v-btn v-if="examSessionStudentId" color="success" @click="go()">
                    DEVAM ET
                  </v-btn>
                  <v-btn v-else color="success" @click="go()"> BAŞLAT </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <b-button v-if="examSessions.length > count" size="sm" @click="count += count"
              >Devamını Yükle</b-button>
          </div>
          <div class="pt-5" v-else>
            <v-alert type="warning"> Katılabileceğiniz oturum bulunamadı </v-alert>
          </div>
        </v-card-text>
      </v-card>
    </b-overlay>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment'
import Swal from 'sweetalert2'

export default {
  props: ['examSessions', 'loading', 'title'],
  name: 'oturumlar-sayfasi',
  data() {
    return {
      examSessionStudent: {},
      examSessionStudentId: 0,
      count: 5,
      dialog: false,
      IsDigital: false,
      ExamSessionId: 0,
      StartDate: '',
      EndDate: ''
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).locale('tr').format('Do MMMM YYYY HH:mm') : ''
    }
  },
  methods: {
    close() {
      this.examSessionStudent = {}
      this.examSessionStudentId = 0
      this.dialog = false
    },
    
    go() {
      ApiService.setHeader()
      ApiService.put('api/StudentExam/ExamSessionStudent', {
        Id: this.examSessionStudentId,
        ExamSessionId: this.ExamSessionId,
        IsDigital: this.IsDigital,
        IsCompleted: false
      })
        .then(() => {
          this.$router.push({ name: 'oturum', params: { id: this.ExamSessionId } })
        })
        .catch((r) => {
          Swal.fire({
            toast: true,
            position: 'center',
            icon: 'error',
            title: r.response.data.SummaryMessage,
            showConfirmButton: false,
            timer: 4000
          })
        })
    },
    goBack() {
      this.$router.push({ name: 'sinavlar' })
    },
    getExamSessionStudentFromApi() {
      ApiService.setHeader()
      ApiService.get('api/StudentExam/ExamSessionStudent/' + this.ExamSessionId)
        .then((data) => {
          var now = moment()
          var StartDate = moment(this.StartDate)
          var EndDate = moment(this.EndDate)
          if (StartDate < now && now < EndDate) {
            this.examSessionStudent = data.data
            this.examSessionStudentId = this.examSessionStudent.Id
            this.IsDigital = this.examSessionStudent.IsDigital
            this.dialog = true
          } else {
            this.dialog = false
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Seçtiğiniz oturum henüz başlamamıştır',
              showConfirmButton: false,
              timer: 1500
            })
          }
        })
        .catch((r) => {
          Swal.fire({
            toast: true,
            position: 'center',
            icon: 'error',
            title: r.response.data.SummaryMessage,
            showConfirmButton: false,
            timer: 4000
          })
        })
    }
  }
}
</script>
